import React, { useEffect, useContext } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import Scrollmagic from "scrollmagic"
import { TweenMax } from "gsap"
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../graphql/fragments"

import { LangContext } from "../../context/langContext"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../components/cover-main/cover-main"
import { Section, Container } from "../../components/grid/grid"
import { HeadingBasic } from "../../components/heading/heading"
import { ButtonLink } from "../../components/utils/utils"
import { NavTabs, TabPane } from "../../components/ak-tabs/tabs"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const HistoryPage = ({ location }) => {
  const { lang } = useContext(LangContext)
  useEffect(() => {
    ScrollMagicPluginGsap(Scrollmagic, TweenMax)
    const controller = new Scrollmagic.Controller({
      globalSceneOptions: {
        triggerHook: 0.2,
        duration: "420%",
      },
    })
    new Scrollmagic.Scene({
      triggerElement: "#trigger",
    })
      .setPin("#trigger", { pushFollowers: false })
      .addTo(controller)
  }, [])

  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "img-section.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1439) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        page(id: "about-us/history-duta-pertiwi", idType: URI) {
          title
          blocks {
            name
            ...CoreParagraphBlock
            ...CoreCoverBlock
            ...CoreGroupBlock
          }
          translation(language: ID) {
            title
            blocks {
              name
              ...CoreParagraphBlock
              ...CoreCoverBlock
              ...CoreGroupBlock
            }
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const wordPress =
    data?.wordPress?.page?.translation?.blocks || data?.wordPress?.page?.blocks
  const seo =
    data?.wordPress?.page?.translation?.seo || data?.wordPress?.page?.seo
  const cover = wordPress && wordPress.find(x => x.name === "core/cover")

  return (
    <Layout location={location} currentLocation={location.uri} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {wordPress && (
        <>
          <CoverMain
            h1={
              data?.wordPress?.page?.translation?.title ||
              data?.wordPress?.page?.title
            }
            img={
              cover
                ? cover.attributes.url
                : data.imgCoverMain.childImageSharp.fluid
            }
            className="cover-md"
          >
            <Breadcrumbs path={location.uri} className="breadcrumb-light" />
          </CoverMain>

          <Section className="d-lg-none">
            <Container>
              <HeadingBasic
                text={[
                  `The property market in Indonesia started gaining momentum in late 1980s with surge of projects and customers lining up to have affordable houses with upscale facilities.`,
                  `Duta Pertiwi has only started building residences in 1988, but soon after, the young company boosted the industry with projects like apartments, shopping centers, office buildings, even township and industrial estates with level of creativity and innovation that was unheard before.`,
                ]}
                className="mb-0"
              />
            </Container>
          </Section>

          <Section className="py-4 d-none d-lg-block timeline-section">
            <div className={`nav-tabs-wrapper d-flex`}>
              <ul class={`nav nav-tabs`} role="tablist">
                <li className={`nav-item`}>
                  <Link
                    className={`nav-link`}
                    role="tab"
                    to="/id/about-us/history"
                  >
                    Sinar Mas Land Limited
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link
                    className={`nav-link`}
                    role="tab"
                    to="/id/about-us/history-bumi-serpong-damai"
                  >
                    PT Bumi Serpong Damai Tbk
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <div className={`nav-link active`} role="tab">
                    PT Duta Pertiwi
                  </div>
                </li>
              </ul>
            </div>
            <Container>
              <div className="mt-5 d-flex flex-row">
                <div className="history-wrapper">
                  <div id="trigger" className="text pb-5 pb-lg-0">
                    {wordPress.map(
                      (item, i) =>
                        item.name === "core/paragraph" && (
                          <p key={`excerpt-${i}`} dangerouslySetInnerHTML={{ __html: item.attributes?.content || ""  }}  />
                        )
                    )}
                  </div>
                </div>

                <div className="history-wrapper">
                  <ul className="timeline">
                    {wordPress.map(
                      (item, i) =>
                        item.name === "core/group" && (
                          <li key={`history-list-${i}`} className="text">
                            <div className="row">
                              {item.innerBlocks?.map((blockItem, index) => {
                                if (blockItem.name === "core/heading") {
                                  return (
                                    <div
                                      key={`history-heading-${index}`}
                                      className="col-lg-2"
                                    >
                                      <div className="timeline-year">
                                        {blockItem.attributes.content}
                                      </div>
                                    </div>
                                  )
                                }
                                return <div key={`empty-group-${i}`}></div>
                              })}
                              <div className="col-lg-10">
                                {item.innerBlocks?.map((blockItem, index) => {
                                  if (blockItem.name === "core/paragraph") {
                                    return (
                                      <div className="timeline-content">
                                        <ReactMarkdown
                                          key={`paragraph-${i}-${index}`}
                                          source={blockItem.attributes.content}
                                          escapeHtml={false}
                                        />
                                      </div>
                                    )
                                  } else if (blockItem.name === "core/image") {
                                    const padding = index > 1 ? "py-4" : "pb-4"
                                    return (
                                      <img
                                        key={`history-image-${i}-${index}`}
                                        className={padding}
                                        alt={`history-img-${i}-${index}`}
                                        src={blockItem.attributes.url}
                                      />
                                    )
                                  } else if (
                                    blockItem.name === "core/buttons"
                                  ) {
                                    return (
                                      <ButtonLink
                                        item={blockItem}
                                        key={`history-btn-${i}-${index}`}
                                      />
                                    )
                                  }
                                  return <div key={`empty-block-${i}`}></div>
                                })}
                              </div>
                            </div>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>
            </Container>
          </Section>

          <Section className="timeline-section d-lg-none">
            <Container>
              <div className="row">
                <div className="col-lg-6 offset-lg-6">
                  <ul className="timeline">
                    {wordPress.map(
                      (item, i) =>
                        item.name === "core/group" && (
                          <li key={`history-list-${i}`} className="text">
                            <div className="row">
                              {item.innerBlocks?.map((blockItem, index) => {
                                if (blockItem.name === "core/heading") {
                                  return (
                                    <div
                                      key={`history-heading-${index}`}
                                      className="col-lg-2"
                                    >
                                      <div className="timeline-year">
                                        {blockItem.attributes.content}
                                      </div>
                                    </div>
                                  )
                                }
                                return <div key={`empty-group-${i}`}></div>
                              })}
                              <div className="col-lg-10">
                                {item.innerBlocks?.map((blockItem, index) => {
                                  if (blockItem.name === "core/paragraph") {
                                    return (
                                      <div className="timeline-content">
                                        <ReactMarkdown
                                          key={`paragraph-${i}-${index}`}
                                          source={blockItem.attributes.content}
                                          escapeHtml={false}
                                        />
                                      </div>
                                    )
                                  } else if (blockItem.name === "core/image") {
                                    const padding = index > 1 ? "py-4" : "pb-4"
                                    return (
                                      <img
                                        key={`history-img-${i}-${index}`}
                                        alt={`history-img-${i}-${index}`}
                                        className={padding}
                                        src={blockItem.attributes.url}
                                      />
                                    )
                                  } else if (
                                    blockItem.name === "core/buttons"
                                  ) {
                                    return (
                                      <ButtonLink
                                        item={blockItem}
                                        key={`history-btn-${i}-${index}`}
                                      />
                                    )
                                  }
                                  return <div key={`empty-block-${i}`}></div>
                                })}
                              </div>
                            </div>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>
            </Container>
          </Section>
        </>
      )}
    </Layout>
  )
}

export default HistoryPage
